/* istanbul ignore file */
/* eslint-disable */

/* From the official Highcarts forum
 * Adds in a new condition to render a round dial
 * https://www.highcharts.com/forum/viewtopic.php?t=40754
 */
export const gaugeWrapper = (H: any) => {
  H.seriesTypes.gauge.prototype.translate = function () {
    const merge = H.merge,
      pInt = H.pInt,
      pick = H.pick,
      isNumber = H.isNumber,
      series = this,
      yAxis = series.yAxis,
      options = series.options,
      center = yAxis.center;

    series.generatePoints();
    series.points.forEach((point: any) => {
      const dialOptions = merge(options.dial, point.dial);
      const isRectanglePoint = point.series.userOptions.isRectanglePoint;
      const radius = (pInt(pick(dialOptions.radius, 80)) * center[2]) / 200;
      const baseLength =
        (pInt(pick(dialOptions.baseLength, 70)) * radius) / 100;
      const rearLength =
        (pInt(pick(dialOptions.rearLength, 10)) * radius) / 100;
      const baseWidth = dialOptions.baseWidth || 3;
      const topWidth = dialOptions.topWidth || 1;
      let overshoot = options.overshoot;
      let rotation =
        yAxis.startAngleRad + yAxis.translate(point.y, null, null, null, true);

      // Handle the wrap and overshoot options
      if (isNumber(overshoot)) {
        overshoot = (overshoot / 180) * Math.PI;
        rotation = Math.max(
          yAxis.startAngleRad - overshoot,
          Math.min(yAxis.endAngleRad + overshoot, rotation)
        );
      } else if (options.wrap === false) {
        rotation = Math.max(
          yAxis.startAngleRad,
          Math.min(yAxis.endAngleRad, rotation)
        );
      }

      rotation = (rotation * 180) / Math.PI;

      // Checking series to draw dots
      if (isRectanglePoint) {
        //draw new dial
        point.shapeType = "path";
        point.shapeArgs = {
          d: dialOptions.path || [
            "M",
            -rearLength + 20,
            -baseWidth / 2,
            "a",
            baseWidth / 2 + baseWidth * 3,
            baseWidth / 2 + baseWidth * 3,
            0,
            1,
            1,
            (baseWidth / 2 + baseWidth * 3) * 2,
            0,
            "a",
            baseWidth / 2 + baseWidth * 3,
            baseWidth / 2 + baseWidth * 3,
            0,
            1,
            1,
            (baseWidth / 2 + baseWidth * 3) * -2,
            0,
          ],
          translateX: center[0],
          translateY: center[1],
          rotation: rotation,
          style: `stroke: ${dialOptions.borderColor}; stroke-width: 2;background: ${dialOptions.backgroundColor}`,
        };
      } else {
        //draw standard dial
        point.shapeType = "path";
        point.shapeArgs = {
          d: dialOptions.path || [
            "M",
            -rearLength,
            -baseWidth / 2,
            "L",
            baseLength,
            -baseWidth / 2,
            radius,
            -topWidth / 2,
            radius,
            topWidth / 2,
            baseLength,
            baseWidth / 2,
            -rearLength,
            baseWidth / 2,
            "z",
          ],
          translateX: center[0],
          translateY: center[1],
          rotation: rotation,
        };
      }

      // Positions for data label
      point.plotX = center[0];
      point.plotY = center[1];
    });
  };
};
