import { Action, Icon } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import linkStyle from "./nav-link.scss";

export interface NavLinkProps {
  to: string;
  size?: "small" | "medium" | "large";
  external?: boolean;
  onClick?: () => void;
  className?: string;
}

export const NavLink: React.FunctionComponent<NavLinkProps> = ({
  to,
  children,
  external,
  size = "medium",
  onClick,
  className,
}) => {
  const iconSize = size === "large" ? "lg" : size === "medium" ? "md" : "sm";
  return (
    <div className="nav-link">
      <style jsx>{linkStyle}</style>
      <Action size={size}>
        {external ? (
          <a
            className={className}
            href={to}
            target="_blank"
            rel="noopener nofollow"
            onClick={onClick}
          >
            <span className="button-text">{children} </span>
            <Icon
              name="navigation-chevron-right-circle"
              size={iconSize}
              legacy={true}
            ></Icon>
          </a>
        ) : (
          <Link to={to} onClick={onClick} className={className}>
            <span className="button-text">{children} </span>
            <Icon
              name="navigation-chevron-right-circle"
              size={iconSize}
              legacy={true}
            ></Icon>
          </Link>
        )}
      </Action>
    </div>
  );
};
