import { capitalise, isDefined } from "@wisr/common";
import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsMore from "highcharts/highcharts-more";
import HighChartsSolidGauge from "highcharts/modules/solid-gauge";
import React from "react";
import { GaugeBands, GaugeData } from "../../types/graph";
import { gaugeWrapper } from "./gauge-graph-chart-wrapper";
import gaugeGraphStyle from "./gauge-graph-chart.scss";
import { getGaugeLabel } from "./gauge-graph-chart.utils";

interface GaugeProps {
  gaugeData: GaugeData;
  gaugeBands: GaugeBands[];
  variation?: boolean;
  id?: string;
}

export const GaugeGraphChartComponent: React.FunctionComponent<GaugeProps> = ({
  gaugeData,
  gaugeBands,
  variation,
  id,
}) => {
  if (isDefined(gaugeData)) {
    HighChartsMore(HighCharts);
    HighChartsSolidGauge(HighCharts);
    gaugeWrapper(HighCharts);
    const chartOptions: HighCharts.Options = {
      chart: {
        type: "solidgauge",
        reflow: true,
        height: 250,
      },
      pane: {
        center: ["50%", "75%"],
        size: "100%",
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            backgroundColor: "#fff",
            shape: "solid",
            borderWidth: 0,
          },
        ],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: gaugeData.gaugeLimit,
        title: {
          text: `<div class="gauge-chart-score">${
            gaugeData.gaugeData
          }</div><div class="gauge-chart-label">${
            typeof gaugeData.gaugeData === "number"
              ? capitalise(getGaugeLabel(gaugeData.gaugeData, gaugeBands))
              : gaugeData.customLabel
          }</div>`,
          useHTML: true,
          style: {
            color: "#000",
            textAlign: "center",
          },
        },
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        stops: gaugeBands.map((band) => {
          return [band.bandLimit, band.bandColour];
        }),
        labels: {
          y: 24,
          distance: -6,
          style: {
            fontFamily: "Inter",
            color: "#a3a09b",
            fontSize: "12px",
          },
        },
        plotBands: gaugeBands.map((band, index) => {
          return {
            from: index ? gaugeBands[index - 1].bandLimit : 0,
            to:
              index === gaugeBands.length - 1
                ? gaugeData.gaugeLimit
                : band.bandLimit,
            color: band.bandColour,
            thickness: "8%",
          };
        }),
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          clip: false,
        },
      },
      series: [
        {
          type: "gauge",
          name: gaugeData.gaugeLabel,
          data: [
            {
              y:
                typeof gaugeData.gaugeData === "number"
                  ? gaugeData.gaugeData
                  : null,
            },
          ],
          showInLegend: false,
          isRectanglePoint: true,
          label: {
            enabled: false,
          },
          clip: false,
          dial: {
            borderColor: "#fff",
            backgroundColor: gaugeData.gaugeDialColour,

            rearLength: "-86%",
            baseWidth: 3,
          },
          dataLabels: {
            enabled: false,
          },
          pivot: {
            radius: 0,
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 250,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  y: 16,
                },
              },
              series: [
                {
                  type: "gauge",
                  dial: {
                    rearLength: "-80%",
                    baseWidth: 3,
                  },
                },
              ],
            },
          },
          {
            condition: {
              // Devices under 375px
              maxWidth: 175,
            },
            chartOptions: {
              chart: {
                height: 175,
              },
              yAxis: {
                labels: {
                  y: 20,
                },
              },
              series: [
                {
                  type: "gauge",
                  dial: {
                    rearLength: "-68%",
                    baseWidth: 2,
                  },
                },
              ],
            },
          },
          {
            condition: {
              // Devices under 320px
              maxWidth: 135,
            },
            chartOptions: {
              chart: {
                height: 150,
              },
              yAxis: {
                labels: {
                  y: 16,
                },
              },
              series: [
                {
                  type: "gauge",
                  dial: {
                    rearLength: "-50%",
                    baseWidth: 2,
                  },
                },
              ],
            },
          },
        ],
      },
    };

    return (
      <div className={variation ? `home-variation` : ""} id={id}>
        <style jsx>{gaugeGraphStyle}</style>
        <HighchartsReact highcharts={HighCharts} options={chartOptions} />
      </div>
    );
  }
  return <HighchartsReact />;
};
