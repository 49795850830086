import React from "react";
import { Card, SkeletonLoader } from "@wisr/react-ui";
interface SkeletonLoaderProps {
  variation?: "home" | "" | "compact";
}

import loadingStyle from "./skeleton-loader.scss";

export const SkeletonLoaderWidget = ({ variation }: SkeletonLoaderProps) => {
  if (variation) {
    return (
      <>
        <style jsx>{loadingStyle}</style>
        <div className={`loading-wrapper ${variation}`}>
          {variation === "home" ? (
            <>
              <SkeletonLoader lines={2} />
            </>
          ) : (
            <Card>
              <SkeletonLoader lines={6} />
            </Card>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <style jsx>{loadingStyle}</style>
      <div className={`loading-wrapper`}>
        <div className="grid">
          <div className="item">
            <Card>
              <SkeletonLoader lines={8} />
            </Card>
          </div>
          <div className="item">
            <Card>
              <SkeletonLoader lines={8} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
